import React, { Fragment } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import HelpPageHeader from "./HelpPageHeader";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Help() {
  const navigate = useNavigate();
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);

  const englishContent = `<!DOCTYPE html>
  <html lang="es-ES">
    <head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta charset="utf-8" />
      <title>
      </title>
      <style>
        body { line-height:108%; font-family:Aptos; font-size:11pt }
        h1, h2, h3, h4, h5, h6, p { margin:0pt 0pt 8pt }
        li { margin-top:0pt; margin-bottom:8pt }
        h1 { margin-top:18pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Aptos Display'; font-size:20pt; font-weight:normal; color:#0f4761 }
        h2 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Aptos Display'; font-size:16pt; font-weight:normal; color:#0f4761 }
        h3 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:14pt; font-weight:normal; color:#0f4761 }
        h4 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:italic; color:#0f4761 }
        h5 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; color:#0f4761 }
        h6 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:italic; color:#595959 }
        .Heading7 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:normal; color:#595959 }
        .Heading8 { margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:italic; color:#272727 }
        .Heading9 { margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:normal; color:#272727 }
        .IntenseQuote { margin:18pt 43.2pt; text-align:center; line-height:108%; border-top:0.75pt solid #0f4761; border-bottom:0.75pt solid #0f4761; padding-top:10pt; padding-bottom:10pt; font-size:11pt; font-style:italic; color:#0f4761 }
        .ListParagraph { margin-left:36pt; margin-bottom:8pt; line-height:108%; font-size:11pt }
        .NormalWeb { margin-top:5pt; margin-bottom:5pt; line-height:normal; font-family:'Times New Roman'; font-size:12pt }
        .Quote { margin-top:8pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:11pt; font-style:italic; color:#404040 }
        .Subtitle { margin-bottom:8pt; line-height:108%; font-size:14pt; letter-spacing:0.75pt; color:#595959 }
        .Title { margin-bottom:4pt; line-height:normal; font-family:'Aptos Display'; font-size:28pt; letter-spacing:-0.5pt }
        span.CitaCar { font-style:italic; color:#404040 }
        span.CitadestacadaCar { font-style:italic; color:#0f4761 }
        span.Emphasis { font-style:italic }
        span.Hyperlink { text-decoration:underline; color:#0000ff }
        span.IntenseEmphasis { font-style:italic; color:#0f4761 }
        span.IntenseReference { font-weight:bold; font-variant:small-caps; letter-spacing:0.25pt; color:#0f4761 }
        span.Strong { font-weight:bold }
        span.SubttuloCar { font-size:14pt; letter-spacing:0.75pt; color:#595959 }
        span.Ttulo1Car { font-family:'Aptos Display'; font-size:20pt; color:#0f4761 }
        span.Ttulo2Car { font-family:'Aptos Display'; font-size:16pt; color:#0f4761 }
        span.Ttulo3Car { font-size:14pt; color:#0f4761 }
        span.Ttulo4Car { font-style:italic; color:#0f4761 }
        span.Ttulo5Car { color:#0f4761 }
        span.Ttulo6Car { font-style:italic; color:#595959 }
        span.Ttulo7Car { color:#595959 }
        span.Ttulo8Car { font-style:italic; color:#272727 }
        span.Ttulo9Car { color:#272727 }
        span.TtuloCar { font-family:'Aptos Display'; font-size:28pt; letter-spacing:-0.5pt }
        .content__wrapper * {
          font-size: 12px !important;
          font-family: 'Poppins', sans-serif !important;
          color: black !important;
        }
      </style>
    </head>
    <body>
    <div class="content__wrapper">
    <p style="text-align:center; line-height:108%; font-size:16pt">
          <strong><u>Terms of Use</u></strong>
        </p>
        <p style="text-align:center; line-height:108%; font-size:16pt">
          <strong>&#xa0;</strong>
        </p>
        <p>
          SODEXO establishes strong and lasting relationships based on mutual trust with its clients, partners, consumers, and associates: ensuring that their data is secure and remains confidential is a top priority for SODEXO.
        </p>
        <p>
          SODEXO complies with all Spanish and European Union regulations and legal provisions regarding the protection of personal data.
        </p>
        <p>
          SODEXO applies a very strict privacy policy to ensure the protection of the personal data of individuals using its websites and other applications:
        </p>
        <p>
          - Users maintain control of their own data. Data is treated transparently, confidentially, and securely.
        </p>
        <p>
          - SODEXO maintains a constant commitment with its users to protect their personal data in accordance with Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights (hereinafter, the "Organic Law"), as amended (hereinafter, the "LPD"), and the General Data Protection Regulation of the European Union, of April 27, 2016 (hereinafter, the "GDPR").
        </p>
        <p>
          - SODEXO has a dedicated team for the protection of personal data, consisting of a data protection officer of the group registered with the Spanish Data Protection Agency (AEPD) and a network of contact persons dedicated to the protection of personal data.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>PURPOSES OF THE POLICY</strong>
        </p>
        <p>
          SODEXO takes the protection of your personal data very seriously. We have developed this policy to inform you of the conditions under which we collect, process, use, and protect your personal data. Please read it carefully to familiarize yourself with the categories of personal data subject to collection and processing, how we use the data, and with whom we may exchange it. This policy also describes your rights and how to contact us to exercise them or ask any questions you may have regarding the protection of your personal data.
        </p>
        <p>
          This policy may be amended, supplemented, or updated, particularly to comply with any developments in legal, regulatory, jurisprudential, or technical matters that may occur. However, your personal data will always be processed in accordance with the policy in force at the time of data collection, unless a mandatory legal provision determines otherwise and must be applied retroactively. This policy is an integral part of the Terms of Use of the website.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>IDENTIFICATION AND CONTACT DATA OF THE DATA CONTROLLER</strong>
        </p>
        <p>
          The name of the data controller is:
        </p>
        <p>
          SODEXO IBERIA, S.A, with VAT number A- 08427296.
        </p>
        <p>
          Federico Mompou Street, 5. Las Tablas Business Park, Building 1, Floor 1, 28050 – Madrid
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>DEFINITIONS</strong>
        </p>
        <p>
          - "Personal data": Any information related to an identifiable natural person or that can be identified, directly or indirectly, in reference to an identification number or one or more specific factors of said person.
        </p>
        <p>
          - "we" or "our": SODEXO IBERIA, S.A (hereinafter "SODEXO").
        </p>
        <p>
          - "you": Any user or visitor to the website.
        </p>
        <p>
          - "Website": The SODEXO website available at https://es.sodexo.com
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>COLLECTION AND SOURCE OF PERSONAL DATA</strong>
        </p>
        <p>
          We are likely to collect your personal data directly (specifically, through data collection forms on our website) or indirectly (specifically, through service providers and/or technologies on our website). We commit to obtaining your consent and/or allowing you to object to the use of your data for certain purposes when necessary. You will be informed in any circumstance of the purposes for which your data is collected through different online data collection forms and the cookie policy.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>TYPES OF PERSONAL DATA WE COLLECT AND USE</strong>
        </p>
        <p>
          We may specifically collect and process the following types of personal data:
        </p>
        <p>
          - Data you provide when filling out website forms (for example, for subscription purposes, participation in surveys, marketing, etc.).
        </p>
        <p>
          - Data you provide for authentication purposes.
        </p>
        <p>
          - Data you provide to complete orders or provide a service.
        </p>
        <p>
          - Data you provide through posts, comments, or other content you publish on the website.
        </p>
        <p>
          Personal data marked with an asterisk in data collection forms is mandatory, as it is necessary to complete any placed orders. If this mandatory information is not available, these transactions cannot be processed.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>AUTOMATICALLY COLLECTED PERSONAL DATA</strong>
        </p>
        <p>
          We collect some data automatically when you visit the website to personalize and improve your experience. We collect this data through various methods, such as:
        </p>
        <p>
          - IP addresses: An IP address is a unique identifier that some electronic devices use to identify and communicate with each other on the Internet. When you visit our website, we may use your device's IP address to connect you to the website. We use this information to determine the general physical location of the device and to know the geographic locations where visitors are located.
        </p>
        <p>
          - Statistics: The website uses Google Analytics to generate statistical reports. These reports indicate, for example, the number of users who visited the website, pages visited, and the geographic regions where website users are located. Information collected through statistics may include, for example, your IP address, the website that directed you to our website, and the type of device you used. Your IP address is hidden in our systems and will only be used when necessary to resolve a technical problem, manage the website, and obtain information about user preferences. Access to website traffic data is limited to authorized personnel. We do not use this data to identify visitors or share it with third parties.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>SOCIAL MEDIA</strong>
        </p>
        <p>
          You can click on specific social media icons such as Twitter, Facebook, or LinkedIn, among others, on our website. Social media creates a more familiar atmosphere on the website and helps promote the website through the sharing option. Video-sharing services enrich the video content of our website and increase its visibility. If you click on any of these buttons, we may access personal information you have made public and is accessible through your profiles on these social networks. We do not create or use separate databases from these social networks based on the personal information you have published, and we do not process any data related to your privacy through these media. If you do not want us to access personal information about you that you have posted in public spaces on your profile on these social networks, you should use the procedures provided by the relevant social networks to limit access to this information.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>PURPOSES FOR WHICH WE USE PERSONAL DATA</strong>
        </p>
        <p>
          We use your personal data specifically for the following purposes:
        </p>
        <p>
          - Responding to your requests, such as information requests, searches, newsletters, or other content.
        </p>
        <p>
          - Providing requested services and offers on our website and/or in any of our establishments.
        </p>
        <p>
          - Conducting surveys and collecting statistics.
        </p>
        <p>
          - Personalizing and improving your experience on our website.
        </p>
        <p>
          - Offering you our products and services and/or the products and services of our partners.
        </p>
        <p>
          - When making online purchases.
        </p>
        <p>
          - Any other purpose about which we will inform you, if applicable, when collecting your data.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>LEGAL BASIS FOR THE PROCESSING OF PERSONAL DATA</strong>
        </p>
        <p>
          We process your personal data as part of the fulfillment and administration of the contractual relationship we have with you, based on our legitimate interest in improving the quality and operational excellence of the services we offer, or in accordance with certain regulatory obligations. Your personal data may also be processed based on your prior consent in cases where, under certain circumstances, your consent is requested.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>DISCLOSURE OF PERSONAL DATA</strong>
        </p>
        <p>
          The security and confidentiality of your personal data are of utmost importance to us. Therefore, we limit access to your personal data only to members of our staff who need this information to process your orders or
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          &#xa0;provide the requested service. We will not disclose your personal data to any unauthorized third parties. However, we may share your personal data with entities of the Sodexo Group and authorized service providers (e.g., technical service providers [hosting, maintenance], consultants, etc.) whom we may engage to provide our services. We do not authorize our service providers to use or disclose your data except to the extent necessary to provide services on our behalf or comply with legal obligations. We may also share personal data about you (i) if required by law or legal proceedings; (ii) in response to requests from public authorities or other administrations; or (iii) if we believe that the transfer of this data is necessary or timely to prevent any physical harm or financial loss or in connection with an investigation into suspected or proven unlawful activity.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>RETENTION PERIOD OF YOUR PERSONAL DATA</strong>
        </p>
        <p>
          We will retain your data only for the period necessary to fulfill the purposes for which it was collected and processed. This period may be extended, if necessary, for any period of time ordered by any legal or regulatory provision that may be applicable.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>SENSITIVE PERSONAL DATA</strong>
        </p>
        <p>
          As a general rule, we do not collect sensitive personal data through our website. By sensitive personal data, we refer to any information related to ethnic or racial origin, political opinions, religious or philosophical beliefs, union membership, and data related to health or life or sexual orientation of a natural person. This definition also includes personal data related to convictions and offenses. If it is strictly necessary to collect this data to achieve the purposes for which the processing is carried out, we will do so in accordance with local legal requirements for the protection of personal data and, in particular, with your explicit prior consent and under the conditions described in this confidentiality policy.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>PERSONAL INFORMATION AND CHILDREN</strong>
        </p>
        <p>
          The website is designed for use by adults capable of entering into a contract under the laws of their country of residence. Users under the age of 16 or without legal capacity must obtain the consent of their legal guardians before submitting their data to the website. The age limit of 16 may be reduced to 13 depending on the local legislation of your usual place of residence.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>TRANSFER OF PERSONAL DATA</strong>
        </p>
        <p>
          As Sodexo is an international group, your personal data may be transmitted to internal or external recipients authorized to provide services on our behalf and located in countries outside the European Union or the European Economic Area where a sufficient level of protection of personal data is not offered. To ensure the security and confidentiality of personal data transmitted in this way, we will take all necessary measures to ensure that this data receives sufficient protection, such as the signing of standard contractual clauses of the European Commission or other equivalent measures.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>YOUR RIGHTS</strong>
        </p>
        <p>
          In accordance with applicable law, you have certain rights regarding the processing of your personal data.
        </p>
        <p>
          - Right to access: You have the right to request access to your personal data. You can also request the correction of inaccurate personal data or request the completion of incomplete data.
        </p>
        <p>
          - Right to erasure: Your right to be forgotten enables you to request the deletion of your personal data when:
        </p>
        <p>
          &#xa0; - The data is no longer necessary for the purposes for which it was collected and processed.
        </p>
        <p>
          &#xa0; - You choose to withdraw your consent.
        </p>
        <p>
          &#xa0; - You object to the processing.
        </p>
        <p>
          &#xa0; - Your data has been processed unlawfully.
        </p>
        <p>
          &#xa0; - Your data must be deleted to comply with a legal obligation.
        </p>
        <p>
          &#xa0; - It is necessary to delete the data to ensure compliance with current legislation.
        </p>
        <p>
          - Right to restriction: You can also request the restriction of the processing of your personal data if:
        </p>
        <p>
          &#xa0; - You dispute the accuracy of the data.
        </p>
        <p>
          &#xa0; - We no longer need the data for processing purposes.
        </p>
        <p>
          &#xa0; - You object to the processing of the data for legitimate reasons.
        </p>
        <p>
          - Right to data portability: You may request, where applicable, the portability of your personal data that you have provided to Sodexo in a structured, commonly used, and machine-readable format. You have the right to transmit this data to another controller without hindrance from Sodexo when:
        </p>
        <p>
          &#xa0; 1. The processing of your personal data is based on consent or a contract.
        </p>
        <p>
          &#xa0; 2. The processing is carried out by automated means.
        </p>
        <p>
          &#xa0; You can also request that your personal data be transmitted to a third party of your choice (when technically possible).
        </p>
        <p>
          - Right to object to processing: You can object (i.e., exercise your "opt-out" right) to the processing of your personal data, especially in relation to profiling or marketing communications. When we process your personal data based on your consent, you can withdraw your consent at any time.
        </p>
        <p>
          - Right not to be subject to a decision based solely on automated processing of data: You have the right not to be subject to a decision based solely on automated processing that has legal effects on you or significantly affects you.
        </p>
        <p>
          - Right to lodge a complaint: If you have any issues or complaints regarding the protection of your personal data, you have the right to lodge a complaint with the Supervisory Authority for personal data protection in your habitual residence, workplace, or the place of the alleged infringement, regardless of whether you have suffered damages. You also have the right to file your complaint with the courts where Sodexo has its establishment or your habitual residence. To exercise your rights, you can write to us at the following address: <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="f6908398959f9998d89a998692d89385b6859992938e99d895999b">[email&#160;protected]</a>, or use the following online form: https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c51cde17-e99e-4699-80ce-892748f9ad1a/ea661bbf-3bfb-434b-bd69-6eddf340a9ae.html. You can also file a complaint with the Supervisory Authority for data protection in the country of your habitual residence, workplace, or the place of the alleged infringement, regardless of whether you have suffered damages. You also have the right to file your complaint with the courts where Sodexo has its establishment or your habitual residence.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>SECURITY</strong>
        </p>
        <p>
          We will implement all possible technical and organizational security measures to ensure the security and confidentiality of your personal data during processing. Given the nature of personal data and the risks related to its processing, we will take all necessary precautions to maintain the security of the data, particularly to prevent distortion, damage, or unauthorized access by third parties (physical protection of facilities, authentication procedures with employees, secure access through identifiers and confidential passwords, connection logs, encryption of certain data, etc.).
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>LINKS TO OTHER SITES</strong>
        </p>
        <p>
          Occasionally, we provide links to other websites for practical and informational purposes. Almost all of these websites belong to Sodexo, and some of them operate independently of our websites and are not under our control. These websites are operated by third parties and have their own privacy recommendations or terms of use, which we strongly recommend reading. We accept no responsibility for the content of these sites, the products and services they may offer, or any other use made of them.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>UPDATES TO THE CONFIDENTIALITY POLICY</strong>
        </p>
        <p>
          This policy will enter into force on May 25, 2018. We may update or amend this confidentiality policy as necessary and when necessary. In this case, amendments will only take effect 30 business days after the amendment date. Please check this page periodically if you want to be informed of any possible changes.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>UNSUBSCRIBE</strong>
        </p>
        <p>
          If you have subscribed to certain services through our website and no longer wish to receive emails, please refer to the corresponding "unsubscribe" page of the service to which you are subscribed.
        </p>
        <p>
          &#xa0;
        </p>
        <p>
          <strong>CONTACT</strong>
        </p>
        <p>
          <span style="font-family:'Segoe UI'; color:#374151">If you have any questions or comments regarding this policy, please feel free to contact us at the following address: </span><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="6107140f02080e0f4f0d0e11054f041221120e0504190e4f020e0c">[email&#160;protected]</a><span style="font-family:'Segoe UI'; color:#374151"> or through the following online form: </span><a href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c51cde17-e99e-4699-80ce-892748f9ad1a/ea661bbf-3bfb-434b-bd69-6eddf340a9ae.html" target="_new" style="text-decoration:none"><span class="Hyperlink" style="border:0.75pt solid #d9d9e3; font-family:'Segoe UI'">https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c51cde17-e99e-4699-80ce-892748f9ad1a/ea661bbf-3bfb-434b-bd69-6eddf340a9ae.html</span></a><span style="font-family:'Segoe UI'; color:#374151"> </span>
        </p>
        <p>
          <span style="font-family:'Segoe UI'; color:#374151">&#xa0;</span>
        </p>
        <p>
          &#xa0;
        </p>
    </div>
        
  </body>
  </html>`;

  const spanishContent = `
  <!DOCTYPE html>
<html lang="es-ES">
	<head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
		<meta charset="utf-8" />
		<title>
		</title>
		<style>
    body { line-height:108%; font-family:Aptos; font-size:11pt }
    h1, h2, h3, h4, h5, h6, p { margin:0pt 0pt 8pt }
    li { margin-top:0pt; margin-bottom:8pt }
    h1 { margin-top:18pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Aptos Display'; font-size:20pt; font-weight:normal; color:#0f4761 }
    h2 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Aptos Display'; font-size:16pt; font-weight:normal; color:#0f4761 }
    h3 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:14pt; font-weight:normal; color:#0f4761 }
    h4 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:italic; color:#0f4761 }
    h5 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; color:#0f4761 }
    h6 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:italic; color:#595959 }
    .Heading7 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:normal; color:#595959 }
    .Heading8 { margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:italic; color:#272727 }
    .Heading9 { margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:Aptos; font-size:11pt; font-weight:normal; font-style:normal; color:#272727 }
    .IntenseQuote { margin:18pt 43.2pt; text-align:center; line-height:108%; border-top:0.75pt solid #0f4761; border-bottom:0.75pt solid #0f4761; padding-top:10pt; padding-bottom:10pt; font-size:11pt; font-style:italic; color:#0f4761 }
    .ListParagraph { margin-left:36pt; margin-bottom:8pt; line-height:108%; font-size:11pt }
    .NormalWeb { margin-top:5pt; margin-bottom:5pt; line-height:normal; font-family:'Times New Roman'; font-size:12pt }
    .Quote { margin-top:8pt; margin-bottom:8pt; text-align:center; line-height:108%; font-size:11pt; font-style:italic; color:#404040 }
    .Subtitle { margin-bottom:8pt; line-height:108%; font-size:14pt; letter-spacing:0.75pt; color:#595959 }
    .Title { margin-bottom:4pt; line-height:normal; font-family:'Aptos Display'; font-size:28pt; letter-spacing:-0.5pt }
    span.CitaCar { font-style:italic; color:#404040 }
    span.CitadestacadaCar { font-style:italic; color:#0f4761 }
    span.Emphasis { font-style:italic }
    span.Hyperlink { text-decoration:underline; color:#0000ff }
    span.IntenseEmphasis { font-style:italic; color:#0f4761 }
    span.IntenseReference { font-weight:bold; font-variant:small-caps; letter-spacing:0.25pt; color:#0f4761 }
    span.Strong { font-weight:bold }
    span.SubttuloCar { font-size:14pt; letter-spacing:0.75pt; color:#595959 }
    span.Ttulo1Car { font-family:'Aptos Display'; font-size:20pt; color:#0f4761 }
    span.Ttulo2Car { font-family:'Aptos Display'; font-size:16pt; color:#0f4761 }
    span.Ttulo3Car { font-size:14pt; color:#0f4761 }
    span.Ttulo4Car { font-style:italic; color:#0f4761 }
    span.Ttulo5Car { color:#0f4761 }
    span.Ttulo6Car { font-style:italic; color:#595959 }
    span.Ttulo7Car { color:#595959 }
    span.Ttulo8Car { font-style:italic; color:#272727 }
    span.Ttulo9Car { color:#272727 }
    span.TtuloCar { font-family:'Aptos Display'; font-size:28pt; letter-spacing:-0.5pt }
    .content__wrapper * {
      font-size: 12px !important;
      font-family: 'Poppins', sans-serif !important;
      color: black !important;
    }
		</style>
	</head>
	<body>
  <div class="content__wrapper">
			<p style="margin-bottom:14pt; line-height:normal; font-size:18pt">
				<strong><span style="font-family:Arial; color:#134394">Términos de uso</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<em><span style="font-family:Arial; ">SODEXO establece relaciones sólidas y duraderas basadas en la confianza mutua con sus clientes, socios, consumidores y asociados: garantizar que sus datos estén seguros y permanezcan en confidencialidad es una prioridad absoluta para SODEXO.</span></em>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<em><span style="font-family:Arial; ">SODEXO cumple toda la normativa y disposiciones legales de España y la Unión Europea en materia de protección de datos personales.</span></em>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<em><span style="font-family:Arial; ">SODEXO aplica una política de privacidad muy estricta para garantizar la protección de los datos personales de las personas que usan sus sitios web y otras aplicaciones:</span></em>
			</p>
			<ul style="margin:0pt; padding-left:0pt">
				<li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">&#xa0;</span><em><span style="font-family:Arial; font-size:13.5pt; ">Los usuarios mantienen el control de sus propios datos. Los datos se tratan de manera transparente, confidencial y segura.</span></em>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<em><span style="font-family:Arial; font-size:13.5pt; ">SODEXO mantiene con sus usuarios un compromiso constante para proteger sus datos personales de conformidad con la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante, la Ley Orgánica), en su forma enmendada (en adelante, la "LPD"), y el Reglamento General de Protección de Datos de la Unión Europea, de 27 de abril de 2016 (en adelante, el "RGPD").</span></em>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:14pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<em><span style="font-family:Arial; font-size:13.5pt; ">SODEXO dispone de un equipo dedicado a la protección de datos personales formado por un delegado de protección de datos del grupo registrado en la AEPD (Agencia Española de Protección de Datos) y una red de personas de contacto dedicadas a la protección de datos personales.</span></em>
				</li>
			</ul>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">FINES DE LA POLÍTICA</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">SODEXO se toma muy en serio la protección de sus datos personales.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Hemos desarrollado esta política para informarle de las condiciones en virtud de las cuales recogemos, tratamos, utilizamos y protegemos sus datos personales. Léala detenidamente para familiarizarse con las categorías de datos personales que están sujetas a la recogida y al tratamiento, el modo en que usamos los datos y con quién podemos intercambiarlos. En esta política también se describen los derechos que tiene y la manera de ponerse en contacto con nosotros para ejercerlos o plantearnos cualquier pregunta que pueda tener en relación con la protección de sus datos personales.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Esta política puede enmendarse, complementarse o actualizarse, en particular con el fin de cumplir con cualquier desarrollo en materia legal, reglamentaria, jurisprudencial o técnica que pueda producirse. No obstante, sus datos personales se tratarán siempre con arreglo a la política en vigor en el momento de la recogida de los datos, a menos que una prescripción legal obligatoria determine lo contrario y deba aplicarse de manera retroactiva.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Esta política es una parte integrante de las</span><span style="font-family:Arial">&#xa0;</span><a href="https://www.doctolib.fr/terms" style="text-decoration:none"><u><span style="font-family:Arial; color:#0000ff">Condiciones de uso</span></u></a><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial">del sitio web.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">DATOS IDENTIFICATIVOS Y DE CONTACTO DEL RESPONSABLE DEL TRATAMIENTO</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">El nombre del responsable del tratamiento es:</span>
			</p>
			<p style="line-height:108%; font-size:13.5pt">
				<span style="font-family:Arial">SODEXO IBERIA, S.A, con CIF A- 08427296.</span>
			</p>
			<p style="line-height:108%; font-size:13.5pt">
				<span style="font-family:Arial">Calle Federico Mompou, 5. Parque Empresarial Las Tablas, Edificio 1, Planta 1 28050 – Madrid</span>
			</p>
			<p style="line-height:108%; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">DEFINICIONES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">"</span><strong><span style="font-family:Arial; ">Datos personales</span></strong><span style="font-family:Arial">" Toda información relacionada con una persona física identificable o que puede identificarse, de manera directa o indirecta, en referencia a un número de identificación o a uno o más factores específicos de dicha persona.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">"</span><strong><span style="font-family:Arial; ">nosotros</span></strong><span style="font-family:Arial">" o "</span><strong><span style="font-family:Arial; ">nuestro</span></strong><span style="font-family:Arial">"SODEXO IBERIA, S.A (en adelante "</span><strong><span style="font-family:Arial; ">SODEXO</span></strong><span style="font-family:Arial">").</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">"</span><strong><span style="font-family:Arial; ">usted</span></strong><span style="font-family:Arial">"Cualquier usuario o visitante del sitio web.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">"</span><strong><span style="font-family:Arial; ">Sitio web</span></strong><span style="font-family:Arial">"El sitio web de SODEXO disponible en la dirección</span><span style="font-family:Arial">&#xa0;</span><a href="https://es.sodexo.com/" style="text-decoration:none"><em><u><span style="font-family:Arial; color:#0000ff">https://es.sodexo.com</span></u></em></a>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">RECOGIDA Y ORIGEN DE LOS DATOS PERSONALES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Lo más probable es que recojamos sus datos personales de manera directa (en concreto, a través de los formularios de recogida de datos de nuestro sitio web) o indirecta (en concreto, a través de los proveedores de servicios y/o tecnologías de nuestro sitio web).</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Nos comprometemos a obtener su consentimiento y/o permitirle que se oponga al uso de sus datos para determinados fines cuando sea necesario.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Se le informará en cualquier circunstancia de los fines para los cuales se recogen sus datos a través de los distintos formularios de recogida de datos en línea y la</span><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial">política de</span><a href="https://www.doctolib.fr/cookies" style="text-decoration:none"><u><span style="font-family:Arial; color:#0000ff">&#xa0;</span></u><u><span style="font-family:Arial; color:#0000ff">cookies</span></u></a><span style="font-family:Arial">.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span><strong><span style="font-family:Arial; ">TIPOS DE DATOS PERSONALES QUE RECOGEMOS Y USAMOS</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Podremos, de manera específica, recoger y tratar los tipos de datos personales siguientes:</span>
			</p>
			<ul style="margin:0pt; padding-left:0pt">
				<li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">los datos que facilite cuando rellene formularios del sitio web (por ejemplo, con fines de suscripción, de participación en encuestas, de marketing, etc.);</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">los datos que facilite con fines de autenticación;</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">los datos que facilite para cumplimentar pedidos o prestar un servicio;</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:14pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">los datos que facilite a través de publicaciones, comentarios o contenido de otra índole que publique en el sitio web.</span>
				</li>
			</ul>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Los datos personales marcados con un asterisco en los formularios de recogida de datos son obligatorios, puesto que son necesarios para cumplimentar cualquier pedido efectuado. Si no se dispone de esta información obligatoria, no se podrán procesar estas transacciones.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">DATOS PERSONALES QUE RECOGEMOS AUTOMÁTICAMENTE</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Recogemos algunos datos de manera automática cuando visita el sitio web con el fin de personalizar y mejorar su experiencia. Recogemos estos datos mediante el uso de varios métodos, como:</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">Direcciones IP</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Una dirección IP es un identificador exclusivo que algunos dispositivos electrónicos emplean para poder identificarse y comunicarse entre sí en Internet. Cuando consulta nuestro sitio web, podemos utilizar la dirección IP de su dispositivo para conectarle al sitio web. Usamos esta información para determinar la ubicación física general del dispositivo y conocer las ubicaciones geográficas donde se encuentran los visitantes.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">Estadísticas</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">El sitio web utiliza Google Analytics para generar informes estadísticos. Estos informes nos indican, por ejemplo, el número de usuarios que consultaron el sitio web, las páginas visitadas y las regiones geográficas donde se encuentran los usuarios del sitio web. La información recogida mediante estadísticas puede incluir, por ejemplo, su dirección IP, el sitio web que le ha dirigido a nuestro sitio web y el tipo de dispositivo que ha utilizado. Su dirección IP se oculta en nuestros sistemas y solo se utilizará cuando sea necesario para solucionar un problema técnico, para administrar el sitio web y para obtener información sobre las preferencias de los usuarios. El acceso a los datos sobre el tráfico del sitio web está limitado al personal autorizado. No utilizamos estos datos para identificar a los visitantes ni los compartimos con terceros.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span><strong><span style="font-family:Arial; ">REDES SOCIALES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Puede hacer clic en los iconos específicos de redes sociales como Twitter, Facebook o LinkedIn, entre otras, que hay en nuestro sitio web.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Las redes sociales crean una atmósfera más familiar en el sitio web y ayudan a promocionar el sitio web mediante la opción de compartir. Los servicios para compartir vídeos enriquecen el contenido de vídeo de nuestro sitio web y aumentan su visibilidad.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Si hace clic en alguno de estos botones, podremos acceder a la información personal que haya hecho pública y sea accesible a través de los perfiles que tenga en dichas redes sociales. No creamos ni usamos bases de datos aparte de estas redes sociales a partir de la información personal que ha publicado y no tratamos ningún dato relacionado con su vida privada a través de estos medios.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Si no desea que tengamos acceso a la información personal acerca de usted que ha publicado en los espacios públicos de su perfil en estas redes sociales, debería utilizar los procedimientos facilitados por las redes sociales en cuestión para limitar el acceso a esta información.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">FINES PARA LOS CUALES USAMOS DATOS PERSONALES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Usamos sus datos personales específicamente para los fines siguientes:</span>
			</p>
			<ul style="margin:0pt; padding-left:0pt">
				<li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">responder a las solicitudes que tenga, como solicitudes de información, búsquedas, el boletín o contenido de otro tipo;</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">prestar los servicios y ofertas solicitados en nuestro sitio web y/o en alguno de nuestros establecimientos;</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">realizar encuestas y recopilar estadísticas;</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">personalizar y mejorar su experiencia en nuestro sitio web;</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">ofrecerle nuestros productos y servicios y/o los productos y servicios de nuestros socios;</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">a la hora de realizar compras on-line;</span>
				</li>
				<li style="margin-left:27.6pt; margin-bottom:14pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
					<span style="font-family:Arial; font-size:13.5pt">cualquier otro fin acerca del cual le informaremos, si procede, cuando recojamos sus datos.</span>
				</li>
			</ul>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">BASE LEGAL PARA EL TRATAMIENTO DE DATOS PERSONALES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Tratamos sus datos personales como parte del cumplimiento y la administración de la relación contractual que mantenemos con usted, con base en nuestro interés legítimo para mejorar la calidad y la excelencia operativa de los servicios que le ofrecemos o de conformidad con determinadas obligaciones normativas.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Sus datos personales también podrán tratarse con base en su consentimiento previo en el caso de que, bajo determinadas circunstancias, se solicitara su consentimiento.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">COMUNICACIÓN DE DATOS PERSONALES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">La seguridad y la confidencialidad de sus datos personales son de suma importancia para nosotros. Es por ello que limitamos el acceso a sus datos personales únicamente a los miembros de nuestro personal que necesiten disponer de esta información para procesar sus pedidos o prestar el servicio solicitado.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">No comunicaremos sus datos personales a ningún tercero no autorizado. Podremos, sin embargo, compartir sus datos personales con entidades del Grupo Sodexo y con los proveedores de servicios autorizados (por ejemplo, proveedores de servicio técnico [alojamiento, mantenimiento], consultores, etc.) a quienes podamos recurrir con el fin de prestar nuestros servicios. No autorizamos a nuestros proveedores de servicios a usar ni comunicar sus datos, excepto en la medida necesaria para prestar los servicios en nuestro nombre o satisfacer obligaciones legales. Asimismo, podremos compartir datos personales acerca de usted (i) si alguna ley o procedimiento legal así nos lo exige; (ii) en respuesta a peticiones de autoridades públicas u otras administraciones; o (iii) si creemos que la transferencia de estos datos es necesaria u oportuna para evitar cualquier daño físico o pérdida financiera o en relación con una investigación sobre una actividad ilícita sospechosa o demostrada</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">PERÍODO DE CONSERVACIÓN DE SUS DATOS PERSONALES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Conservaremos sus datos únicamente durante el período de tiempo necesario para cumplir los fines para los cuales se recogieron y trataron. Este período podrá prolongarse, si procede, durante cualquier período de tiempo ordenado por cualquier disposición legal o normativa que pueda ser aplicable.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">DATOS PERSONALES SENSIBLES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Como regla general, no recogemos datos personales sensibles a través de nuestro sitio web. Por</span><span style="font-family:Arial">&#xa0;</span><em><span style="font-family:Arial; ">datos personales sensibles</span></em><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial">nos referimos a cualquier información relacionada con el origen étnico o racial, las opiniones políticas, las creencias religiosas o filosóficas, la afiliación sindical y los datos de salud o relacionados con la vida sexual o la orientación sexual de una persona física. Esta definición también incluye los datos personales relacionados con sentencias condenatorias y delitos.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">En el caso de que fuera estrictamente necesario recoger estos datos para alcanzar los fines para los cuales se lleva a cabo el tratamiento, lo haremos con arreglo a los requisitos legales locales para la protección de datos personales y, en particular, con su consentimiento expreso previo y bajo las condiciones descritas en esta política de confidencialidad.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">INFORMACIÓN PERSONAL Y NIÑOS</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">El sitio web está diseñado para que lo utilicen personas adultas con capacidad para firmar un contrato en virtud de la legislación de su país de residencia.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Los usuarios menores de 16 años o sin capacidad legal deben obtener el consentimiento de sus tutores legales antes de enviar sus datos al sitio web.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">El límite de 16 años de edad podrá reducirse a 13 años en función de la legislación local de su lugar habitual de residencia.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">TRANSFERENCIA DE DATOS PERSONALES</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Puesto que Sodexo es un grupo internacional, sus datos personales podrán transmitirse a destinatarios internos o externos con permiso para prestar servicios en nuestro nombre y ubicados en países fuera de la Unión Europea o el Espacio Económico Europeo donde no se ofrece un nivel suficiente de protección de datos personales.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Para garantizar la seguridad y la confidencialidad de los datos personales transmitidos de este modo, tomaremos todas las medidas necesarias para garantizar que estos datos reciban suficiente protección, como la firma de cláusulas contractuales tipo de la Comisión Europea u otras medidas equivalentes.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span><strong><span style="font-family:Arial; ">SUS DERECHOS</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">De conformidad con la ley aplicable, usted dispone de determinados derechos en relación con el tratamiento de sus datos personales.</span>
			</p>
			<table style="margin-bottom:0pt; border-spacing:1.5pt">
				<tr>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<strong><span style="font-family:Arial; ">Derecho al acceso</span></strong>
						</p>
					</td>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">Tiene derecho a solicitar el</span><span style="font-family:Arial">&#xa0;</span><strong><span style="font-family:Arial; ">acceso</span></strong><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial">a sus datos personales. También puede solicitar la</span><span style="font-family:Arial">&#xa0;</span><strong><span style="font-family:Arial; ">rectificación</span></strong><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial">de datos personales inexactos o solicitar que se completen datos incompletos.</span>
						</p>
					</td>
				</tr>
				<tr>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<strong><span style="font-family:Arial; ">Derecho a la supresión</span></strong>
						</p>
					</td>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:14pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">Su derecho a ser olvidado le capacita para solicitar que se</span><span style="font-family:Arial">&#xa0;</span><strong><span style="font-family:Arial; ">supriman</span></strong><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial">sus datos personales cuando:</span>
						</p>
						<ul style="margin:0pt; padding-left:0pt">
							<li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">los datos ya no sean necesarios para cumplir los fines para los cuales se recogieron y trataron;</span>
							</li>
							<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">usted elija retirar su consentimiento;</span><span style="font-family:Arial; font-size:12pt">&#xa0;</span>
							</li>
							<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">usted se oponga al tratamiento;</span>
							</li>
							<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">sus datos se hayan tratado de manera ilícita;</span>
							</li>
							<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">sus datos deban suprimirse para cumplir con una obligación legal; o</span>
							</li>
							<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">sea necesario suprimir los datos para garantizar el cumplimiento de la legislación actual.</span>
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<strong><span style="font-family:Arial; ">Derecho a la limitación</span></strong>
						</p>
					</td>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:14pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">También puede solicitar la</span><span style="font-family:Arial">&#xa0;</span><strong><span style="font-family:Arial; ">limitación</span></strong><strong><span style="font-family:Arial; ">&#xa0;</span></strong><span style="font-family:Arial">del tratamiento de sus datos personales si:</span>
						</p>
						<ul style="margin:0pt; padding-left:0pt">
							<li style="margin-top:14pt; margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">usted impugna la exactitud de los datos;</span>
							</li>
							<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">ya no necesitamos los datos para los fines del tratamiento; y</span>
							</li>
							<li style="margin-left:27.6pt; margin-bottom:0pt; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt">
								<span style="font-family:Arial; font-size:12pt">usted se opone al tratamiento de los datos por razones legítimas.</span>
							</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<strong><span style="font-family:Arial; ">Derecho a la portabilidad de los datos</span></strong>
						</p>
					</td>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:14pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">Puede solicitar, en su caso, la portabilidad de sus Datos personales que haya facilitado a Sodexo, en un formato estructurado, de uso común y legible por máquina, tiene derecho a transmitir estos datos a otro responsable sin impedimento por parte de Sodexo cuando:</span>
						</p>
						<ol style="margin:0pt; padding-left:0pt">
							<li style="margin-top:14pt; margin-left:33.01pt; margin-bottom:0pt; line-height:normal; padding-left:2.99pt; font-family:Arial; font-size:12pt">
								el tratamiento de sus Datos personales se basa en el consentimiento o en un contrato;
							</li>
							<li style="margin-left:33.01pt; margin-bottom:14pt; line-height:normal; padding-left:2.99pt; font-family:Arial; font-size:12pt">
								el tratamiento se lleva a cabo por medios automatizados.
							</li>
						</ol>
						<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">También puede solicitar que sus Datos personales se transmitan a un tercero de su elección (cuando sea técnicamente posible)</span>
						</p>
						<p style="margin-top:14pt; margin-bottom:0pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">&#xa0;</span>
						</p>
					</td>
				</tr>
				<tr>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<strong><span style="font-family:Arial; ">Derecho a oponerse al procesamiento</span></strong>
						</p>
					</td>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:14pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">Puede oponerse (es decir, ejercer su derecho a "opt-out") al procesamiento de sus Datos personales, especialmente en relación con la elaboración de perfiles o las comunicaciones de marketing. Cuando procesamos sus Datos personales sobre la base de su consentimiento, puede retirar su consentimiento en cualquier momento.</span>
						</p>
						<p style="margin-top:14pt; margin-bottom:0pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">&#xa0;</span>
						</p>
					</td>
				</tr>
				<tr>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<strong><span style="font-family:Arial; ">Derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado de datos</span></strong>
						</p>
					</td>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">Tiene derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado que tenga efectos jurídicos sobre usted o que le afecte significativamente.</span>
						</p>
					</td>
				</tr>
				<tr>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<strong><span style="font-family:Arial; ">Derecho a presentar una reclamación</span></strong>
						</p>
					</td>
					<td style="padding:0.75pt; vertical-align:middle">
						<p style="margin-bottom:0pt; line-height:normal; font-size:12pt">
							<span style="font-family:Arial">Si tiene algún problema o queja en relación con la protección de sus datos personales, tiene derecho a presentar una reclamación ante Autoridad de Supervisión de datos personales de su residencia habitual, lugar de trabajo o lugar de la supuesta infracción, independientemente de si ha sufrido daños y perjuicios. También tiene derecho a presentar su reclamación ante los tribunales donde Sodexo tiene su establecimiento o en su residencia habitual.</span>
						</p>
					</td>
				</tr>
			</table>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Para ejercer sus derechos, puede escribirnos a la dirección siguiente:</span><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial"><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="83e5f6ede0eaecedadefecf3e7ade6f0c3f0ece7e6fbecade0ecee">[email&#160;protected]</a></span><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial">, o en el siguiente formulario en línea:</span><a href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c51cde17-e99e-4699-80ce-892748f9ad1a/ea661bbf-3bfb-434b-bd69-6eddf340a9ae.html" style="text-decoration:none"><u><span style="font-family:Arial; color:#0000ff">&#xa0;</span></u><u><span style="font-family:Arial; color:#0000ff">https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c51cde17-e99e-4699-80ce-892748f9ad1a/ea661bbf-3bfb-434b-bd69-6eddf340a9ae.html</span></u></a>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">También puede presentar una queja ante la Autoridad de Supervisión de protección de datos en el país de su residencia habitual, lugar de trabajo o lugar de la supuesta infracción, independientemente de si ha sufrido daños y perjuicios. También tiene derecho a presentar su reclamación ante los tribunales donde Sodexo tiene su establecimiento o en su residencia habitual.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">SEGURIDAD</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Implementaremos todas las medidas de seguridad técnicas y organizativas posibles para garantizar la seguridad y la confidencialidad de sus datos personales durante el tratamiento.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Para ello, dada la naturaleza de los datos personales y los riesgos relacionados con su tratamiento, adoptaremos todas las precauciones necesarias con el fin de mantener la seguridad de los datos y, en particular, para evitar la distorsión, el daño o el acceso no autorizado de terceros (protección física de las instalaciones, procedimientos de autenticación con empleados, acceso seguro mediante identificadores y contraseñas confidenciales, un registro de conexiones, cifrado de determinados datos, etc.).</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">ENLACES A OTROS SITIOS</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Puntualmente, facilitamos enlaces a otros sitios web con fines prácticos e informativos. Casi todos estos sitios web pertenecen a Sodexo y algunos de ellos funcionan independientemente de nuestros sitios web y no están bajo nuestro control. Estos sitios web están operados por terceros y cuentan con sus propias recomendaciones de confidencialidad o términos de uso, cuya lectura le recomendamos encarecidamente. No aceptamos ninguna responsabilidad en relación con el contenido de estos sitios, los productos y servicios que allí se puedan ofrecer o cualquier otro uso que se haga de ellos.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">&#xa0;</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">ACTUALIZACIONES DE LA POLÍTICA DE CONFIDENCIALIDAD</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Esta política entrará en vigor el 25 de mayo de 2018. Podremos actualizar o enmendar esta política de confidencialidad según sea necesario y cuando sea necesario. En este caso, las enmiendas solo entrarán en vigor transcurrido un período de 30 días hábiles desde la fecha de la enmienda. Consulte esta página periódicamente si desea estar informado de cualquier posible cambio.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">CANCELACIÓN DE LA SUSCRIPCIÓN</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Si se ha suscrito a determinados servicios a través de nuestro sitio web y ya no desea seguir recibiendo correos electrónicos, consulte la página "cancelar la suscripción" correspondiente del servicio al que está suscrito.</span>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<strong><span style="font-family:Arial; ">CONTACTO</span></strong>
			</p>
			<p style="margin-top:14pt; margin-bottom:14pt; line-height:normal; font-size:13.5pt">
				<span style="font-family:Arial">Si tiene alguna cuestión o comentario en relación con esta política, no dude en ponerse en contacto con nosotros en la dirección siguiente:</span><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial"><a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="c0a6b5aea3a9afaeeeacafb0a4eea5b380b3afa4a5b8afeea3afad">[email&#160;protected]</a></span><span style="font-family:Arial">&#xa0;</span><span style="font-family:Arial">o o en el siguiente formulario en línea:</span><a href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c51cde17-e99e-4699-80ce-892748f9ad1a/ea661bbf-3bfb-434b-bd69-6eddf340a9ae.html" style="text-decoration:none"><u><span style="font-family:Arial; color:#0000ff">&#xa0;</span></u><u><span style="font-family:Arial; color:#0000ff">https://privacyportal-eu-cdn.onetrust.com/dsarwebform/c51cde17-e99e-4699-80ce-892748f9ad1a/ea661bbf-3bfb-434b-bd69-6eddf340a9ae.html</span></u></a>
			</p>
			<p>
				&#xa0;
			</p>
			<p>
				&#xa0;
			</p>
			<p>
				&#xa0;
			</p>
			<p>
				&#xa0;
			</p>
			<p>
				&#xa0;
			</p>
      </div>
</body>
</html>`;

  const { t } = useTranslation();
  return (
    <div className="MainWhole-wrapper-container">
      <Header help={true} />
      <div className="Help-Page-Main-content-wrapper">
        <div className="Help-page-Header-div">
          <div className="Help-page-Header-inner-div-one">
            <h4 className="Help-page-Header-div-h4">
              {/* {t('Help_page_Main_heading')} */}
              {storelanguage === "English" ? "Help" : "ayuda"}
            </h4>
          </div>
        </div>
        <div className="HelpUs_page_accordian_content_wrapper">
          {process.env.REACT_APP_SITE_NAME === "Sodexo" && (
            <Accordion style={{ marginTop: "8px" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                // style={{  margin:''}}
              >
                <>
                  {" "}
                  <p className="Help_page_content_heading">
                    {storelanguage === "English"
                      ? "Terms and Conditions"
                      : "Términos y condiciones"}
                  </p>
                </>
              </AccordionSummary>
              <AccordionDetails className="HelpPage_Accordian_Details_wrapper">
                {storelanguage === "English" ? (
                  <div
                    className="text-left"
                    dangerouslySetInnerHTML={{
                      __html: englishContent.replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></div>
                ) : (
                  <div
                    className="text-left"
                    dangerouslySetInnerHTML={{
                      __html: spanishContent.replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></div>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          <>
            {process.env.REACT_APP_SITE_NAME === "Kogakuin" && (
              <>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ margin: "" }}
                  >
                    <>
                      {" "}
                      <p className="Help_page_content_heading">
                        {storelanguage === "English"
                          ? " Privacy Policy"
                          : "politica sulla riservatezza"}
                      </p>
                    </>
                  </AccordionSummary>
                  <AccordionDetails className="HelpPage_Accordian_Details_wrapper">
                    <>
                      <h3 className="Help_page-content-h3">
                        {" "}
                        {storelanguage === "English"
                          ? "INTRODUCTION"
                          : "INTRODUZIONE"}{" "}
                      </h3>
                      <p className="Help_page-content-p">
                        {" "}
                        Ottonomy, Inc. (“Ottonomy,” “we,” “us,” or “our”){" "}
                        {storelanguage === "English"
                          ? "respects the privacy of its Users"
                          : "rispetta la privacy dei propri Utenti"}{" "}
                        (“User,” “your,” or “you”).
                        {storelanguage === "English"
                          ? "This Privacy Policy"
                          : "Questa Informativa sulla privacy"}{" "}
                        (the “Privacy Policy”){" "}
                        {storelanguage === "English"
                          ? "explains how we collect, use, disclose, and safeguard your information when you use Ottonomy Platform"
                          : "spiega come raccogliamo, utilizziamo, divulghiamo e proteggiamo le tue informazioni quando utilizzi la piattaforma Ottonomy"}{" "}
                        (the “Platform”) through Ottonomy’s website at
                        https://www.ottonomy.io/ (the “Website”).{" "}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        Ottonomy{" "}
                        {storelanguage === "English"
                          ? "is committed to protecting the privacy of its Users whose information is collected and stored while using Ottonomy’s Platform through our Website. This Privacy Policy is applicable to our Website, and Platform."
                          : "si impegna a proteggere la privacy dei propri utenti le cui informazioni vengono raccolte e archiviate durante l utilizzo della piattaforma di Ottonomy attraverso il nostro sito Web. La presente Informativa sulla privacy è applicabile al nostro sito Web e alla nostra piattaforma."}{" "}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "The capitalized terms have the same meaning as ascribed in our Terms of Use or Terms of Service as applicable, unless otherwise noted here"
                          : "I termini in maiuscolo hanno lo stesso significato attribuito nei nostri Termini di utilizzo o Termini di servizio a seconda dei casi, se non diversamente specificato qui"}{" "}
                      </p>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT. BY ACCESSING OR USING OUR WEBSITE AND PLATFORM, YOU AGREE TO ACCEPT ALL THE TERMS CONTAINED IN THIS PRIVACY POLICY AND ACKNOWLEDGE AND AGREE WITH THE PRACTICES DESCRIBED HEREIN. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS AND USE OUR WEBSITE AND PLATFORM"
                          : "SI PREGA DI LEGGERE ATTENTAMENTE QUESTA INFORMATIVA SULLA PRIVACY PER COMPRENDERE LE NOSTRE POLITICHE E PRATICHE RIGUARDANTI LE VOSTRE INFORMAZIONI E COME LE TRATTEREMO. ACCEDENDO O UTILIZZANDO IL NOSTRO SITO WEB E LA PIATTAFORMA, L' UTENTE ACCETTA DI ACCETTARE TUTTI I TERMINI CONTENUTI NELLA PRESENTE INFORMATIVA SULLA PRIVACY E DI RICONOSCERE E ACCETTARE LE PRATICHE QUI DESCRITTE. SE NON SI ACCETTANO I TERMINI DI QUESTA INFORMATIVA SULLA PRIVACY, SI PREGA DI NON ACCEDERE E UTILIZZARE IL NOSTRO SITO WEB E LA PIATTAFORMA"}{" "}
                      </p>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "IF YOU HAVE ANY QUESTIONS REGARDING THIS PRIVACY POLICY, PLEASE SEND US AN EMAIL AT"
                          : "IN CASO DI DOMANDE RIGUARDANTI LA PRESENTE INFORMATIVA SULLA PRIVACY, INVIACI UN'E-MAIL A"}{" "}
                        INFO@OTTONOMY.IO.{" "}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "WE DO NOT SELL YOUR PERSONAL INFORMATION, NOR DO WE INTEND TO DO SO. WE DO NOT GIVE ACCESS TO YOUR PERSONAL INFORMATION TO THIRD PARTIES EXCEPT TO SUBPROCESSORS TO ASSIST US IN THE PROVISION OF OUR SERVICES TO YOU."
                          : "NON VENDIAMO LE TUE INFORMAZIONI PERSONALI, NÉ INTENIAMO FARLO. NON FORNIAMO L'ACCESSO ALLE VOSTRE INFORMAZIONI PERSONALI A TERZI AD ECCEZIONE DEI SUBPROCESSORI CHE CI AIUTANO NELLA FORNITURA DEI NOSTRI SERVIZI."}{" "}
                      </p>
                      <h3 className="Help_page-content-h3">
                        {storelanguage === "English"
                          ? "WHAT INFORMATION DO WE COLLECT?"
                          : "QUALI INFORMAZIONI RACCOGLIAMO?"}{" "}
                      </h3>
                      <div className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "When you register to use our Website or Platform, we collect personal information (also referred to as personally identifiable information or “PII”) which may include your name, address, online contact information such as your email address or username, phone number, and other personal information. The information so collected will be stored on our servers."
                          : "Quando ti registri per utilizzare il nostro sito Web o la nostra piattaforma, raccogliamo informazioni personali (denominate anche informazioni di identificazione personale o 'PII') che possono includere il tuo nome, indirizzo, informazioni di contatto online come il tuo indirizzo e-mail o nome utente, numero di telefono e altre informazioni personali. Le informazioni così raccolte saranno archiviate sui nostri server."}
                        <ul>
                          <li>
                            {storelanguage === "English"
                              ? "Geolocation and Equipment Information.  We may collect information that does not personally identify you such as (i) your geolocation, and (ii) information about your internet connection, the equipment you use to access our Website or Platform, and usage details."
                              : "Geolocalizzazione e informazioni sull'attrezzatura. Potremmo raccogliere informazioni che non ti identificano personalmente come (i) la tua geolocalizzazione e (ii) informazioni sulla tua connessione Internet, l'attrezzatura che usi per accedere al nostro sito Web o piattaforma e dettagli sull'utilizzo"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Financial Information. We currently do not collect or store any credit cards or bank information, as we are using a third-party payment processor.  However, we will update this Privacy Policy when we start using and storing such information. We will also inform you via reasonable means if we start collecting such information from you."
                              : "Informazioni finanziarie. Al momento non raccogliamo né memorizziamo carte di credito o informazioni bancarie, poiché utilizziamo un processore di pagamento di terze parti. Tuttavia, aggiorneremo la presente Informativa sulla privacy quando inizieremo a utilizzare e archiviare tali informazioni. Ti informeremo anche con mezzi ragionevoli se iniziamo a raccogliere tali informazioni da te."}
                          </li>
                        </ul>
                      </div>
                      <h3 className="Help_page-content-h3">
                        {" "}
                        {storelanguage === "English"
                          ? "HOW DO WE COLLECT INFORMATION?"
                          : "COME RACCOGLIAMO LE INFORMAZIONI?"}{" "}
                      </h3>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We collect personal information from you in the following ways:"
                          : "Raccogliamo informazioni personali da te nei seguenti modi:"}{" "}
                      </p>
                      <div className="Help_page-content-p">
                        <ul>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "At registration on our Website or Platform;"
                              : "Al momento della registrazione sul nostro sito web o piattaforma;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "In email, text, and other electronic messages between you and our Website or Platform;"
                              : "In e-mail, testo e altri messaggi elettronici tra te e il nostro sito Web o piattaforma;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Through mobile and desktop applications your downloads from our Website or Platform, which provides dedicated non-browser based interaction between you and our Website or Platform;"
                              : "Tramite applicazioni mobili e desktop, i tuoi download dal nostro sito Web o piattaforma, che forniscono un'interazione dedicata non basata su browser tra te e il nostro sito Web o piattaforma;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "When you interact with our advertising and applications on third-party website and services, if those applications or advertising include a link to this Privacy Policy;"
                              : "Quando interagisci con la nostra pubblicità e applicazioni su siti Web e servizi di terze parti, se tali applicazioni o pubblicità includono un collegamento alla presente Informativa sulla privacy;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "From you placing an order, which includes details of transactions you carry out on our Website or Platform;"
                              : "Da te che effettui un ordine, che include i dettagli delle transazioni che effettui sul nostro sito web o sulla nostra piattaforma;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "When you subscribe to a newsletter;"
                              : "Quando ti iscrivi a una newsletter;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "From your responses to a survey;"
                              : "Dalle tue risposte a un sondaggio;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "From forms filled out by you;"
                              : "Da moduli compilati da te;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "From records or copies of correspondences (including email addresses) if you contact us;"
                              : "Da registrazioni o copie di corrispondenza (inclusi indirizzi e-mail) se ci contatti;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "From search queries on our Website or Platform; and"
                              : "Dalle query di ricerca sul nostro sito web o piattaforma; E"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "When you post information to be published or displayed on our Website or Platform."
                              : "Quando pubblichi informazioni da pubblicare o visualizzare sul nostro sito Web o piattaforma."}{" "}
                          </li>
                        </ul>
                      </div>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "We collect information from you automatically when you navigate through our Website or Platform in the following ways:"
                          : "Raccogliamo informazioni da te automaticamente quando navighi attraverso il nostro sito Web o piattaforma nei seguenti modi:"}
                      </p>
                      <div className="Help_page-content-p">
                        <ul>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Usage details;"
                              : "Dettagli sull'utilizzo;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "IP addresses;"
                              : "indirizzi IP;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Information obtained through browser cookies;"
                              : "Informazioni ottenute tramite i cookie del browser;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Information obtained through flash cookies;"
                              : "Informazioni ottenute tramite flash cookie;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Web beacons on our Website;"
                              : "Web beacon sul nostro sito Web;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Web beacons on emails sent by us; and "
                              : "Web beacon sulle e-mail inviate da noi; E"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Other tracking technologies."
                              : "Altre tecnologie di tracciamento."}{" "}
                          </li>
                        </ul>
                      </div>
                      <h3 className="Help_page-content-h3">
                        {" "}
                        {storelanguage === "English"
                          ? "HOW DO WE COLLECT INFORMATION?"
                          : "COME RACCOGLIAMO LE INFORMAZIONI?"}
                      </h3>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We use the information that you provide to:"
                          : "Utilizziamo le informazioni fornite per:"}
                      </p>
                      <div className="Help_page-content-p">
                        <ul>
                          {" "}
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Personalize your experience in using our Platform;"
                              : "Personalizza la tua esperienza nell'utilizzo della nostra piattaforma;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Provide you with information, products, or services requested from us;"
                              : "Fornire informazioni, prodotti o servizi richiesti da noi;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Present our Website and Platform and their contents to you;"
                              : "presentarvi il nostro sito Web e la nostra piattaforma e i relativi contenuti;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Provide you with notices about account and/or subscription, including expiration and renewal notices;"
                              : "Fornire all'utente avvisi relativi all'account e/o all'abbonamento, inclusi avvisi di scadenza e rinnovo;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Carry out obligations and enforce rights arising from contracts entered into between you and us, including billing and collection;"
                              : "Adempiere agli obblighi e far valere i diritti derivanti dai contratti stipulati tra te e noi, inclusa la fatturazione e la riscossione;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Notify you about changes to our Website and Platform;"
                              : "Informarti sulle modifiche al nostro sito Web e alla nostra piattaforma;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Allow you to participate in interactive features on our Website and Platform;"
                              : "Consentire all'utente di partecipare a funzionalità interattive sul nostro sito Web e piattaforma;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Improve the Website and Platform;"
                              : "Migliorare il Sito Web e la Piattaforma;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Improve our customer service;"
                              : "Migliorare il nostro servizio clienti;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Administer contests, promotions, and surveys or other Website and Platform features;"
                              : "Amministrare concorsi, promozioni e sondaggi o altre funzionalità del sito Web e della piattaforma;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Process transactions;"
                              : "Elaborare le transazioni;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Anonymize data and aggregate data for statistics;"
                              : "Anonimizza i dati e aggrega i dati per le statistiche;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Contact you for other purposes with your consent;"
                              : "Contattarti per altri scopi con il tuo consenso;"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Contact you about our products and services that may be of interest;"
                              : "Contattarti in merito ai nostri prodotti e servizi che potrebbero interessarti;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Contact you about third parties’ goods and services;"
                              : "Contattarti in merito a beni e servizi di terze parti;"}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Enable the display of advertisements to our advertisers’ target audiences, although personal information is not shared with advertisers without your consent; and"
                              : "Consentire la visualizzazione di annunci pubblicitari al pubblico di destinazione dei nostri inserzionisti, sebbene le informazioni personali non vengano condivise con gli inserzionisti senza il tuo consenso; E"}
                          </li>
                        </ul>
                      </div>
                      <h3 className="Help_page-content-h3">
                        {storelanguage === "English"
                          ? "OUR COOKIE POLICY"
                          : "LA NOSTRA POLITICA SUI COOKIE"}
                      </h3>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Cookies are small pieces of text used to store information on web browsers. Cookies are used to store and receive identifiers and other information on computers, phones, and other devices. Other technologies, including data we store on your web browser or device, identifiers associated with your device, and other software, are used for similar purposes. In this Privacy Policy, we refer to all of these technologies as “Cookies.” "
                          : "I cookie sono piccole porzioni di testo utilizzate per memorizzare informazioni sui browser web. I cookie vengono utilizzati per archiviare e ricevere identificatori e altre informazioni su computer, telefoni e altri dispositivi. Altre tecnologie, inclusi i dati che memorizziamo sul tuo browser web o dispositivo, gli identificatori associati al tuo dispositivo e altri software, vengono utilizzate per scopi simili. Nella presente Informativa sulla privacy, ci riferiamo a tutte queste tecnologie come 'Cookie'."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We use Cookies on our Website to (a) help remember and process items in the shopping cart, (b) understand and save your preferences for future visits, (c) keep track of advertisements, (d) compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future, and (e) allow trusted third-party services that track this information on our behalf.  You can set your browser to refuse all or some browser Cookies, but it may affect your user experience. We honor Do Not Track signals and, if one is in place, we will not track, plant cookies, or use advertising"
                          : "Utilizziamo i cookie sul nostro sito Web per (a) aiutare a ricordare ed elaborare gli articoli nel carrello, (b) comprendere e salvare le tue preferenze per le visite future, (c) tenere traccia degli annunci pubblicitari, (d) compilare dati aggregati sul traffico del sito e interazioni del sito al fine di offrire migliori esperienze e strumenti del sito in futuro e (e) consentire servizi di terze parti affidabili che tracciano queste informazioni per nostro conto. Puoi impostare il tuo browser in modo da rifiutare tutti o alcuni cookie del browser, ma ciò potrebbe influire sulla tua esperienza utente. Onoriamo i segnali Do Not Track e, se ne esiste uno, non tracceremo, installeremo cookie o utilizzeremo pubblicità "}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We allow third party behavioral tracking and links to third-party web pages.  Occasionally, at our discretion, we may include or offer third-party products or services on our Website or Platform.  These third-party sites have separate and independent privacy policies.  We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our Website, App, or Platform and welcome any feedback at about these sites."
                          : "Consentiamo il monitoraggio comportamentale di terze parti e collegamenti a pagine Web di terze parti. Occasionalmente, a nostra discrezione, possiamo includere o offrire prodotti o servizi di terze parti sul nostro sito Web o piattaforma. Questi siti di terze parti hanno politiche sulla privacy separate e indipendenti. Pertanto, non abbiamo alcuna responsabilità per il contenuto e le attività di questi siti collegati. Tuttavia, cerchiamo di proteggere l'integrità del nostro sito Web, app o piattaforma e accogliamo con favore qualsiasi feedback su questi siti."}{" "}
                      </p>
                      <h3 className="Help_page-content-h3">
                        {" "}
                        {storelanguage === "English"
                          ? "DATA SECURITY MEASURES."
                          : "MISURE DI SICUREZZA DEI DATI."}
                      </h3>
                      <div className="Help_page-content-p">
                        {" "}
                        <ul>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Security Measures.  We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.  The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website or Platform, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website or Platform. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on our Website or Platform."
                              : "Misure di sicurezza. Abbiamo implementato misure progettate per proteggere le tue informazioni personali da perdite accidentali e da accesso, uso, alterazione e divulgazione non autorizzati. Tutte le informazioni che ci fornisci sono memorizzate sui nostri server sicuri dietro i firewall. La sicurezza e la protezione delle tue informazioni dipendono anche da te. Laddove ti abbiamo fornito (o dove hai scelto) una password per l'accesso a determinate parti del nostro sito Web o della nostra piattaforma, sei responsabile di mantenere riservata tale password. Ti chiediamo di non condividere la tua password con nessuno. Sfortunatamente, la trasmissione di informazioni via Internet non è completamente sicura. Sebbene facciamo del nostro meglio per proteggere le tue informazioni personali, non possiamo garantire la sicurezza delle tue informazioni personali trasmesse al nostro sito web o piattaforma. Qualsiasi trasmissione di informazioni personali è a proprio rischio. Non siamo responsabili dell'elusione delle impostazioni sulla privacy o delle misure di sicurezza contenute nel nostro sito web o piattaforma."}{" "}
                          </li>
                          <li>
                            {storelanguage === "English"
                              ? "Fair Information Practice Principles.  In the event of a personal data breach, we will notify you within three (3) days via (i) email and/or (ii) our Platform notification system on our Website and/or App.  We agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors."
                              : "Principi di pratica dell'informazione corretta. In caso di violazione dei dati personali, ti informeremo entro tre (3) giorni tramite (i) e-mail e/o (ii) il nostro sistema di notifica della Piattaforma sul nostro Sito Web e/o App. Accettiamo il principio del ricorso individuale, che richiede che gli individui abbiano il diritto di perseguire diritti legalmente applicabili nei confronti dei raccoglitori e dei responsabili del trattamento dei dati che non rispettano la legge. Questo principio richiede non solo che gli individui abbiano diritti esecutivi nei confronti degli utenti dei dati, ma anche che gli individui facciano ricorso ai tribunali o a un'agenzia governativa per indagare e/o perseguire la non conformità da parte dei responsabili del trattamento dei dati."}
                          </li>
                        </ul>{" "}
                      </div>
                      <h3 className="Help_page-content-h3">
                        {" "}
                        {storelanguage === "English"
                          ? "DISCLOSURE OF PERSONAL INFORMATION"
                          : "DIVULGAZIONE DI DATI PERSONALI"}
                      </h3>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "There are times when we may share Personal Information that you have shared with us may be shared by Ottonomy with others to enable us to provide you over Services, including contractors, service providers, and third parties (“Partners”). This section discusses only how Ottonomy may share such information with Partners. We will ensure that our Partners protect your Personal Information. The following describe how and with whom we may share your Personal Information:"
                          : "Ci sono momenti in cui potremmo condividere le informazioni personali che hai condiviso con noi possono essere condivise da Ottonomy con altri per consentirci di fornirti servizi, inclusi appaltatori, fornitori di servizi e terze parti ('Partner'). Questa sezione discute solo come Ottonomy può condividere tali informazioni con i partner. Faremo in modo che i nostri partner proteggano le tue informazioni personali. Quanto segue descrive come e con chi potremmo condividere le tue informazioni personali:"}{" "}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {storelanguage === "English"
                          ? "Disclosure of Personal Information."
                          : "Divulgazione di informazioni personali."}
                      </h6>
                      <div className="Help_page-content-p">
                        <ul>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We may disclose aggregated, de-personalized information about you that does not identify any individual to other parties without restriction, such as for marketing, advertising, or other uses."
                              : "Potremmo divulgare informazioni aggregate e depersonalizzate su di te che non identificano alcun individuo ad altre parti senza restrizioni, ad esempio per marketing, pubblicità o altri usi."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We may disclose personal information to our subsidiaries and affiliates."
                              : "Potremmo divulgare informazioni personali alle nostre consociate e affiliate."}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We may disclose personal information to contractors, services providers, and other third parties."
                              : "Potremmo divulgare informazioni personali ad appaltatori, fornitori di servizi e altre terze parti."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We require all contractors, service providers, and other third parties to whom we disclose your personal information to be under contractual obligations to keep personal information confidential and to use it only for the purposes for which we disclose them."
                              : "Richiediamo a tutti gli appaltatori, fornitori di servizi e altre terze parti a cui divulghiamo le tue informazioni personali di essere soggetti all'obbligo contrattuale di mantenere riservate le informazioni personali e di utilizzarle solo per gli scopi per i quali le divulghiamo."}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We may disclose personal information in the event of a merger, sale of business, etc."
                              : "Potremmo divulgare informazioni personali in caso di fusione, vendita di attività, ecc."}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We may disclose to third parties to market their products and services to you if you have either consented or not opted out of these disclosures."
                              : "Potremmo divulgare a terzi per commercializzare i loro prodotti e servizi a te se hai acconsentito o non hai rinunciato a queste divulgazioni."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We may disclose personal information to third parties to market their products and services if you have either consented or not opted out of these disclosures."
                              : "Potremmo divulgare informazioni personali a terzi per commercializzare i loro prodotti e servizi se hai acconsentito o non hai rinunciato a queste divulgazioni."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We require all other Partners, to whom we disclose your personal information, to enter into contracts with us to keep personal information confidential and use it only for the purposes for which we disclose it to such Partners."
                              : "Richiediamo a tutti gli altri Partner, ai quali divulghiamo le vostre informazioni personali, di stipulare contratti con noi per mantenere riservate le informazioni personali e utilizzarle solo per gli scopi per i quali le divulghiamo a tali Partner."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We may disclose personal information for any other purpose for which you have provided it.:"
                              : "Potremmo divulgare informazioni personali per qualsiasi altro scopo per il quale l'hai fornito.:"}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We may only disclose personal information as described in this Privacy Policy or your consent."
                              : "Possiamo divulgare informazioni personali solo come descritto nella presente Informativa sulla privacy o con il tuo consenso."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We sell personal information to others for their use."
                              : "Vendiamo informazioni personali ad altri per il loro uso."}
                          </li>
                        </ul>
                      </div>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Other Disclosure of Personal Information"
                          : "Altra divulgazione di informazioni personali"}
                      </h6>
                      <div className="Help_page-content-p">
                        <ul>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We will disclose personal information (i) to comply with any court order, law, or legal process, including to respond to any government or regulatory request, (ii) to enforce or apply our Terms of Use or Terms of Service and other agreements, including for billing and collection purposes, (iii) if we believe it is necessary or appropriate to protect the rights, property, or safety of Ottonomy, our customers or others, and/or (iv) if it is necessary or appropriate to protect the rights, property, or safety of Ottonomy, our customers, or others, and this includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction."
                              : "Divulgheremo le informazioni personali (i) per rispettare qualsiasi ordine del tribunale, legge o procedimento legale, incluso per rispondere a qualsiasi richiesta governativa o normativa, (ii) per far rispettare o applicare i nostri Termini di utilizzo o Termini di servizio e altri accordi, anche per scopi di fatturazione e riscossione, (iii) se riteniamo che sia necessario o appropriato per proteggere i diritti, la proprietà o la sicurezza di Ottonomy, dei nostri clienti o di altri, e/o (iv) se è necessario o appropriato per proteggere il diritti, proprietà o sicurezza di Ottonomy, dei nostri clienti o di altri, e ciò include lo scambio di informazioni con altre società e organizzazioni ai fini della protezione dalle frodi e della riduzione del rischio di credito."}{" "}
                          </li>
                        </ul>
                      </div>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Third Party Disclosure."
                          : "Divulgazione di terze parti."}
                      </h6>
                      <div className="Help_page-content-p">
                        <ul>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We do not sell, trade, rent, or otherwise transfer personal information to others, unless we provide you with advance notice.  This does not include our hosting partners and other parties who assist us in operating our Website or Platform, conducting our business, or servicing you, so long as those parties agree to keep this information confidential."
                              : "Non vendiamo, scambiamo, affittiamo o trasferiamo in altro modo le informazioni personali ad altri, a meno che non ti forniamo un preavviso. Ciò non include i nostri partner di hosting e altre parti che ci assistono nella gestione del nostro sito Web o piattaforma, nella conduzione della nostra attività o nella fornitura di assistenza, a condizione che tali parti accettino di mantenere riservate queste informazioni."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "We do not provide non-personally identifiable visitor information for marketing purposes."
                              : "Non forniamo informazioni sui visitatori non identificabili personalmente per scopi di marketing."}{" "}
                          </li>
                        </ul>
                      </div>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Choices Users Have About How Ottonomy Uses and Discloses Information."
                          : "Scelte che gli utenti hanno su come Ottonomy utilizza e divulga le informazioni."}{" "}
                      </h6>
                      <div className="Help_page-content-p">
                        <ul>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Tracking Technologies and Advertising. You can set their browser to refuse some or all the browser cookies, but if you disable or refuse cookies, some parts of our Website may not be accessible or function properly."
                              : "Tecnologie di tracciamento e pubblicità. È possibile impostare il proprio browser in modo da rifiutare alcuni o tutti i cookie del browser, ma se si disattivano o si rifiutano i cookie, alcune parti del nostro sito Web potrebbero non essere accessibili o non funzionare correttamente."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Disclosure of Users’ Information for Third-Party Advertising.  Users can opt-out by (i) checking the relevant form when we collect the data; (ii) logging into the Website or Platform and adjusting their preferences in their account profile by checking or unchecking the relevant boxes, or (iii) emailing us their opt-out request at info@ottonomy.io. Users receiving promotional email can opt-out by sending a return email requesting to be omitted from future promotional email distributions. This opt-out will not apply to information provided by Ottonomy for product purchases, warranty registration, or other transactions."
                              : "Divulgazione delle informazioni degli utenti per la pubblicità di terze parti. Gli utenti possono rinunciare (i) controllando il modulo pertinente quando raccogliamo i dati; (ii) accedendo al sito Web o alla piattaforma e modificando le proprie preferenze nel profilo del proprio account selezionando o deselezionando le caselle pertinenti, o (iii) inviandoci un'e-mail con la richiesta di rinuncia all'indirizzo info@ottonomy.io. Gli utenti che ricevono e-mail promozionali possono annullare la sottoscrizione inviando un'e-mail di risposta che richiede di essere esclusi dalle future distribuzioni di e-mail promozionali. Questa rinuncia non si applicherà alle informazioni fornite da Ottonomy per l'acquisto di prodotti, la registrazione della garanzia o altre transazioni."}{" "}
                          </li>
                          <li>
                            {" "}
                            {storelanguage === "English"
                              ? "Disclosure of User’s Information for Targeted Advertising.  Users can opt-out by (i) checking the relevant form when we collect the data, (ii) logging into the Website or Platform and adjusting their preferences in their account profile by checking or unchecking the relevant boxes, or (iii) emailing us their opt-out request at info@ottonomy.io."
                              : "Divulgazione delle informazioni dell'utente per pubblicità mirata. Gli utenti possono rinunciare (i) controllando il modulo pertinente quando raccogliamo i dati, (ii) accedendo al sito Web o alla piattaforma e modificando le proprie preferenze nel profilo del proprio account selezionando o deselezionando le caselle pertinenti o (iii) inviandoci un'e-mail la loro richiesta di opt-out a info@ottonomy.io."}
                          </li>
                        </ul>
                      </div>
                      <h3 className="Help_page-content-h3">
                        {storelanguage === "English"
                          ? "YOUR CALIFORNIA PRIVACY RIGHTS"
                          : "I TUOI DIRITTI ALLA PRIVACY IN CALIFORNIA"}
                      </h3>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Ottonomy does not sell, trade, or otherwise transfer to outside third parties your “Personal Information” as the term is defined under the California Civil Code Section § 1798.82(h).  Additionally, California Civil Code Section § 1798.83 permits Users of our Website or Platform that are California residents to request certain information regarding our disclosure of their Personal Information to third parties for their direct marketing purposes. To make a request for such disclosure, or identification and/or deletion of Personal Information in all our systems that we store on you, please send an email to info@ottonomy.io or write us at Ottonomy, 1032 2nd St, Santa Monica, CA 90403."
                          : "Ottonomy non vende, commercia o trasferisce in altro modo a terzi esterni le tue 'Informazioni personali' come il termine è definito nella sezione del codice civile della California § 1798.82 (h). Inoltre, la sezione § 1798.83 del codice civile della California consente agli utenti del nostro sito Web o piattaforma che sono residenti in California di richiedere determinate informazioni relative alla nostra divulgazione delle loro informazioni personali a terzi per i loro scopi di marketing diretto. Per richiedere tale divulgazione, o identificazione e/o cancellazione delle informazioni personali in tutti i nostri sistemi che conserviamo su di te, invia un'e-mail a info@ottonomy.io o scrivici a Ottonomy, 1032 2nd St, Santa Monica, CA 90403."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Note that (i) if we delete your Personal Information as requested, we will no longer be able to provide our services to you and (ii) we may need to keep such Personal Information for a while during the shutting down and billing process. If you would like to discuss our Personal Information storage and processing process with us, please send us an email at to info@ottonomy.io or write us at Ottonomy, 1032 2nd St, Santa Monica, CA 90403."
                          : "Tieni presente che (i) se eliminiamo le tue informazioni personali come richiesto, non saremo più in grado di fornirti i nostri servizi e (ii) potremmo aver bisogno di conservare tali informazioni personali per un po' durante il processo di chiusura e fatturazione. Se desideri discutere con noi del nostro processo di archiviazione ed elaborazione delle informazioni personali, inviaci un'e-mail a info@ottonomy.io o scrivici a Ottonomy, 1032 2nd St, Santa Monica, CA 90403."}{" "}
                      </p>
                      <h3 className="Help_page-content-h3">
                        {" "}
                        {storelanguage === "English"
                          ? "MODIFICATIONS TO OUR PRIVACY POLICY"
                          : "MODIFICHE ALLA NOSTRA INFORMATIVA SULLA PRIVACY"}
                      </h3>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "Ottonomy reserves the right, at its sole discretion, to change or modify this Privacy Policy at any time. In the event we modify this Privacy Policy, such modifications shall be binding on you only upon your acceptance of the modified Privacy Policy. We will inform you about the modifications on our Privacy Policy page via email, on our Website or Platform by posting a modified version of the Privacy Policy page, or by a comparable means within a reasonable time period.  Your continued use of our Website or Platform shall constitute your consent to such changes."
                          : "Ottonomy si riserva il diritto, a sua esclusiva discrezione, di cambiare o modificare la presente Informativa sulla privacy in qualsiasi momento. Nel caso in cui dovessimo modificare la presente Informativa sulla privacy, tali modifiche saranno vincolanti per l'utente solo previa accettazione dell'Informativa sulla privacy modificata. Ti informeremo delle modifiche sulla nostra pagina dell'Informativa sulla privacy via e-mail, sul nostro sito Web o piattaforma pubblicando una versione modificata della pagina dell'Informativa sulla privacy o con mezzi comparabili entro un periodo di tempo ragionevole. L'uso continuato del nostro sito Web o della nostra piattaforma costituirà il tuo consenso a tali modifiche."}
                      </p>
                      <h3 className="Help_page-content-h3">
                        {" "}
                        {storelanguage === "English"
                          ? "THIRD PARTY PROCESSORS"
                          : "PROCESSORI DI TERZE PARTI"}
                      </h3>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Our selected partners and service providers may process personal information about you on our behalf as described below:"
                          : "I nostri partner e fornitori di servizi selezionati possono elaborare le informazioni personali su di te per nostro conto come descritto di seguito:"}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Digital Marketing Service Providers We periodically appoint digital marketing agents to conduct marketing activity on our behalf, such activity may result in the compliant processing of personal information. Our appointed data processors include  (i)Prospect Global Ltd (trading as Sopro) Reg. UK Co. 09648733. You can contact Sopro and view their privacy policy here: http://sopro.io. Sopro are registered with the ICO Reg: ZA346877 their Data Protection Officer can be emailed at: dpo@sopro.io."
                          : "Fornitori di servizi di marketing digitale Nominiamo periodicamente agenti di marketing digitale per svolgere attività di marketing per nostro conto, tale attività può comportare il trattamento conforme delle informazioni personali. I nostri responsabili del trattamento dei dati nominati includono (i) Prospect Global Ltd (operante come Sopro) Reg. UK Co. 09648733. Puoi contattare Sopro e visualizzare la loro politica sulla privacy qui: http://sopro.io. Sopro è registrata con il registro ICO: ZA346877, il loro responsabile della protezione dei dati può essere inviato tramite e-mail all'indirizzo: dpo@sopro.io."}
                      </p>
                      <h3 className="Help_page-content-h3">
                        {" "}
                        {storelanguage === "English"
                          ? "CONTACT US"
                          : "CONTATTACI"}
                      </h3>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:"
                          : "Per porre domande o commentare la presente Informativa sulla privacy e le nostre pratiche sulla privacy, contattaci all'indirizzo:"}
                      </p>
                      <div className="Help_page-content-p">
                        <ul>
                          {" "}
                          <li>Hardik Sharma </li>
                          <li> Email: hardik.sharma@ottonomy.io</li>
                          <li>
                            {" "}
                            Address: Ottonomy, 1032 2nd St, Santa Monica, CA
                            90403
                          </li>
                        </ul>
                      </div>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "PLEASE NOTE: IF YOU USE OUR WEBSITE OR PLATFORM, YOU HAVE AGREED TO AND ACCEPTED THE PRACTICES DESCRIBED IN THIS PRIVACY POLICY AND THE TERMS AND CONDITIONS SET FORTH IN OUR TERMS OF USE OR OUR TERMS OF SERVICE, AS APPLICABLE.  IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY OR OUR TERMS OF SERVICE, PLEASE DO NOT USE OUR WEBSITE OR PLATFORM."
                          : "NOTA BENE: SE UTILIZZATE IL NOSTRO SITO WEB O LA PIATTAFORMA, AVETE ACCETTATO E ACCETTATO LE PRATICHE DESCRITTE NELLA PRESENTE INFORMATIVA SULLA PRIVACY E I TERMINI E LE CONDIZIONI STABILITI NEI NOSTRI TERMINI DI UTILIZZO O NELLE NOSTRE CONDIZIONI DI SERVIZIO, A SECONDA DEL CASO. SE NON SI ACCETTANO I TERMINI DI QUESTA INFORMATIVA SULLA PRIVACY OI NOSTRI TERMINI DI SERVIZIO, SI PREGA DI NON UTILIZZARE IL NOSTRO SITO WEB O LA PIATTAFORMA."}
                      </p>
                    </>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{ margin: "" }}
                  >
                    <>
                      <p className="Help_page_content_heading">
                        {" "}
                        {storelanguage === "English"
                          ? "Terms and conditions"
                          : "Termini e condizioni"}{" "}
                      </p>
                    </>
                  </AccordionSummary>
                  <AccordionDetails className="HelpPage_Accordian_Details_wrapper">
                    <>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Welcome to orderat.io!"
                          : "Benvenuto su orderat.io!"}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "These terms and conditions outline the rules and regulations for the use of orderat.io's website, located at http://orderat.io/."
                          : "Questi termini e condizioni delineano le regole e i regolamenti per l'uso del sito Web di orderat.io, che si trova all'indirizzo http://orderat.io/."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "By accessing this website we assume you accept these terms and conditions. Do not continue to use orderat.io if you do not agree to take all of the terms and conditions stated on this page."
                          : "Accedendo a questo sito web assumiamo che tu accetti questi termini e condizioni. Non continuare a utilizzare orderat.io se non accetti di accettare tutti i termini e le condizioni indicati in questa pagina."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "The following terminology applies to these terms and conditions, privacy statement and disclaimer notice and all agreements: client, you and your refers to you, the person log on this website and compliant to the company’s terms and conditions. The company, ourselves, we, our and us, refers to our company. Party, parties, or us, refers to both the client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the client in the most appropriate manner for the express purpose of meeting the client’s needs in respect of provision of the company’s stated services, in accordance with and subject to, prevailing law of netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same."
                          : "La seguente terminologia si applica a questi termini e condizioni, informativa sulla privacy e avviso di esclusione di responsabilità e tutti gli accordi: cliente, tu e il tuo si riferisce a te, la persona che accede a questo sito Web e conforme ai termini e alle condizioni della società. L'azienda, noi stessi, noi, i nostri e noi, si riferisce alla nostra azienda. Festa, feste o noi, si riferisce sia al cliente che a noi stessi. Tutti i termini si riferiscono all'offerta, all'accettazione e alla considerazione del pagamento necessari per intraprendere il processo della nostra assistenza al cliente nel modo più appropriato allo scopo esplicito di soddisfare le esigenze del cliente in relazione alla fornitura dei servizi dichiarati dalla società, in conformità con e soggetto alla legge prevalente dei Paesi Bassi. Qualsiasi uso della suddetta terminologia o di altre parole al singolare, al plurale, in maiuscolo e/o lui/lei o loro, sono considerati intercambiabili e quindi riferiti allo stesso."}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English" ? "Cookies" : "Cookies"}
                      </h6>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We employ the use of cookies. By accessing orderat.io, you agreed to use cookies in agreement with the orderat.io's privacy policy."
                          : "Impieghiamo l'uso dei cookie. Accedendo a orderat.io, hai accettato di utilizzare i cookie in accordo con la politica sulla privacy di orderat.io."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "By use of the uniform resource locator being linked to; or"
                          : "Utilizzando l'uniform resource locator a cui si è collegati; O"}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English" ? "License" : "License"}
                      </h6>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Unless otherwise stated, orderat.io and/or its licensors own the intellectual property rights for all material on orderat.io. All intellectual property rights are reserved. You may access this from orderat.io for your own personal use subjected to restrictions set in these terms and conditions"
                          : "Salvo diversa indicazione, orderat.io e/oi suoi licenziatari detengono i diritti di proprietà intellettuale per tutto il materiale su orderat.io. Tutti i diritti di proprietà intellettuale sono riservati. Puoi accedervi da orderat.io per il tuo uso personale soggetto alle restrizioni stabilite in questi termini e condizioni"}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "You must not:"
                          : "Non devi:"}
                      </p>
                      <ul>
                        <li className="Help_page-content-p">
                          {storelanguage === "English"
                            ? "Republish material from orderat.io"
                            : "Ripubblicare materiale da orderat.io non:"}
                        </li>
                        <li className="Help_page-content-p">
                          {storelanguage === "English"
                            ? "Sell, rent or sub-license material from orderat.io"
                            : "Vendere, noleggiare o concedere in sublicenza il materiale di orderat.io"}
                        </li>
                        <li className="Help_page-content-p">
                          {storelanguage === "English"
                            ? "Reproduce, duplicate or copy material from orderat.io"
                            : "Riprodurre, duplicare o copiare materiale da orderat.io"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "Redistribute content from orderat.io"
                            : "Ridistribuire i contenuti da orderat.io"}
                        </li>
                      </ul>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Orderat.io does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Orderat.io,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Orderat.io shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website."
                          : "Parti di questo sito Web offrono agli utenti l'opportunità di pubblicare e scambiare opinioni e informazioni in determinate aree del sito Web. Orderat.io non filtra, modifica, pubblica o rivede i commenti prima della loro presenza sul sito web. I commenti non riflettono le opinioni e le opinioni di Orderat.io, dei suoi agenti e/o affiliati. I commenti riflettono le opinioni e le opinioni della persona che pubblica le proprie opinioni e opinioni. Nella misura consentita dalle leggi applicabili, Orderat.io non sarà responsabile per i Commenti o per qualsiasi responsabilità, danno o spesa causati e/o subiti a seguito di qualsiasi utilizzo e/o pubblicazione e/o visualizzazione dei Commenti su questo sito web."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Orderat.Io reserves the right to monitor all comments and to remove any comments which can be considered inappropriate, offensive or causes breach of these terms and conditions."
                          : "Orderat.Io si riserva il diritto di monitorare tutti i commenti e di rimuovere qualsiasi commento che possa essere considerato inappropriato, offensivo o causa di violazione di questi termini e condizioni."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "You warrant and represent that:"
                          : "Garantisci e dichiari che:"}
                      </p>
                      <ul>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "You are entitled to post the comments on our website and have all necessary licenses and consents to do so;"
                            : "Hai il diritto di pubblicare i commenti sul nostro sito Web e disporre di tutte le licenze e i consensi necessari per farlo;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "The comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;"
                            : "I commenti non invadono alcun diritto di proprietà intellettuale, inclusi, a titolo esemplificativo, copyright, brevetto o marchio di terzi;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "The comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy"
                            : "I commenti non contengono materiale diffamatorio, calunnioso, offensivo, indecente o altrimenti illegale che costituisca una violazione della privacy"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "The comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity."
                            : "I commenti non verranno utilizzati per sollecitare o promuovere affari o consuetudini o presentare attività commerciali o attività illecite."}
                        </li>
                      </ul>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "You hereby grant orderat.io a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your comments in any and all forms, formats or media."
                          : "Con la presente concedi a orderat.io una licenza non esclusiva per utilizzare, riprodurre, modificare e autorizzare altri a utilizzare, riprodurre e modificare i tuoi commenti in qualsiasi forma, formato o supporto."}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Hyperlinking to our content"
                          : "Collegamento ipertestuale al nostro contenuto"}
                      </h6>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "The following organizations may link to our website without prior written approval:"
                          : "Le seguenti organizzazioni possono collegarsi al nostro sito Web senza previa approvazione scritta:"}
                      </p>
                      <ul>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "Government agencies"
                            : "Agenzie governative"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "Search engines;"
                            : "Motori di ricerca;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "News organizations;"
                            : "testate giornalistiche;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "Online directory distributors may link to our website in the same manner as they hyperlink to the websites of other listed businesses; and"
                            : "I distributori di directory online possono collegarsi al nostro sito Web nello stesso modo in cui collegano ipertestuali ai siti Web di altre aziende elencate; E"}{" "}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "System wide accredited businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our web site"
                            : "Aziende accreditate a livello di sistema, ad eccezione della sollecitazione di organizzazioni senza scopo di lucro, centri commerciali di beneficenza e gruppi di raccolta fondi di beneficenza che potrebbero non avere collegamenti ipertestuali al nostro sito Web"}
                        </li>
                      </ul>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "These organizations may link to our home page, to publications or to other website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site."
                          : "Queste organizzazioni possono collegarsi alla nostra home page, a pubblicazioni o ad altre informazioni sul sito Web purché il collegamento: (a) non sia in alcun modo ingannevole; (b) non implica falsamente la sponsorizzazione, l'approvazione o l'approvazione della parte collegata e dei suoi prodotti e/o servizi; e (c) si inserisce nel contesto del sito della parte che effettua il collegamento."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We may consider and approve other link requests from the following types of organizations:"
                          : "Potremmo prendere in considerazione e approvare altre richieste di collegamento dai seguenti tipi di organizzazioni:"}
                      </p>
                      <ul>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "Commonly-known consumer and/or business information sources;"
                            : "Fonti di informazioni commerciali e/o di consumo comunemente note;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "Under construction - awesome site in the making! Community sites;"
                            : "In costruzione - fantastico sito in costruzione! Siti comunitari;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "associations or other groups representing charities;"
                            : "associazioni o altri gruppi che rappresentano enti di beneficenza;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "online directory distributors;"
                            : "distributori di directory online;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "internet portals;"
                            : "portali internet;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "accounting, law and consulting firms; and"
                            : "studi commercialisti, legali e di consulenza; E"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "educational institutions and trade associations."
                            : "istituti scolastici e associazioni di categoria."}
                        </li>
                      </ul>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of orderat.io; and (d) the link is in the context of general resource information."
                          : "Approveremo le richieste di collegamento da queste organizzazioni se decidiamo che: (a) il collegamento non ci farebbe sembrare sfavorevole a noi stessi o alle nostre aziende accreditate; (b) l'organizzazione non ha alcun record negativo con noi; (c) il vantaggio per noi dalla visibilità del collegamento ipertestuale compensa l'assenza di orderat.io; e (d) il collegamento è nel contesto di informazioni generali sulle risorse."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site."
                          : "Queste organizzazioni possono collegarsi alla nostra home page purché il collegamento: (a) non sia in alcun modo ingannevole; (b) non implica falsamente la sponsorizzazione, l'approvazione o l'approvazione della parte collegata e dei suoi prodotti o servizi; e (c) si inserisce nel contesto del sito della parte che effettua il collegamento."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to orderat.io. Please include your name, your organization name, contact information as well as the url of your site, a list of any urls from which you intend to link to our website, and a list of the urls on our site to which you would like to link. Wait 2-3 weeks for a response."
                          : "Se sei una delle organizzazioni elencate nel precedente paragrafo 2 e sei interessato a collegarti al nostro sito Web, devi informarci inviando una e-mail a orderat.io. Includi il tuo nome, il nome della tua organizzazione, le informazioni di contatto e l'URL del tuo sito, un elenco di tutti gli URL da cui intendi collegarti al nostro sito Web e un elenco degli URL sul nostro sito a cui desideri collegarti collegamento. Attendere 2-3 settimane per una risposta."}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Approved organizations may hyperlink to our website as follows:"
                          : "Le organizzazioni approvate possono collegarsi al nostro sito Web come segue:"}
                      </p>
                      <ul>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "By use of our corporate name; or"
                            : "Utilizzando il nostro nome aziendale; O"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "By use of the uniform resource locator being linked to; or"
                            : "Utilizzando l'uniform resource locator a cui si è collegati; O"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "By use of any other description of our website being linked to that makes sense within the context and format of content on the linking party’s site."
                            : "Utilizzando qualsiasi altra descrizione del nostro sito Web collegato a ciò che ha senso nel contesto e nel formato del contenuto sul sito della parte che effettua il collegamento"}
                        </li>
                      </ul>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "No use of orderat.io's logo or other artwork will be allowed for linking absent a trademark license agreement."
                          : "Non sarà consentito l'uso del logo di orderat.io o di altre opere d'arte per il collegamento in assenza di un accordo di licenza del marchio."}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English" ? "iFrames" : "iFrame"}
                      </h6>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "Without prior approval and written permission, you may not create frames around our webpages that alter in any way the visual presentation or appearance of our website."
                          : "Senza previa approvazione e autorizzazione scritta, non è possibile creare frame attorno alle nostre pagine Web che alterino in alcun modo la presentazione visiva o l'aspetto del nostro sito Web."}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Content liability"
                          : "Responsabilità di contenuto"}
                      </h6>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "We shall not be hold responsible for any content that appears on your website. You agree to protect and defend us against all claims that is rising on your website. No link(s) should appear on any website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights."
                          : "Non saremo ritenuti responsabili per qualsiasi contenuto che appare sul tuo sito web. Accetti di proteggerci e difenderci da tutte le rivendicazioni che stanno sorgendo sul tuo sito web. Nessun link deve apparire su qualsiasi sito Web che possa essere interpretato come diffamatorio, osceno o criminale, o che violi, altrimenti violi o sostenga la violazione o altra violazione dei diritti di terzi."}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Your privacy"
                          : "La tua privacy"}
                      </h6>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "Please read privacy policy"
                          : "Si prega di leggere l'informativa sulla privacy"}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Reservation of rights"
                          : "Riserva di diritti"}
                      </h6>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We reserve the right to request that you remove all links or any particular link to our website. You approve to immediately remove all links to our website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our website, you agree to be bound to and follow these linking terms and conditions."
                          : "Ci riserviamo il diritto di richiedere la rimozione di tutti i collegamenti o di qualsiasi collegamento particolare al nostro sito web. Accetti di rimuovere immediatamente tutti i collegamenti al nostro sito Web su richiesta. Ci riserviamo inoltre il diritto di modificare questi termini e condizioni e la sua politica di collegamento in qualsiasi momento. Collegandoti continuamente al nostro sito Web, accetti di essere vincolato e seguire questi termini e condizioni di collegamento"}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Removal of links from our website"
                          : "Rimozione di link dal nostro sito web"}
                      </h6>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "If you find any link on our website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly"
                          : "Se trovi qualsiasi link sul nostro sito Web offensivo per qualsiasi motivo, sei libero di contattarci e informarci in qualsiasi momento. Prenderemo in considerazione le richieste di rimozione dei collegamenti, ma non siamo obbligati a rispondere direttamente all'utente"}
                      </p>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date."
                          : "Non garantiamo che le informazioni su questo sito Web siano corrette, non garantiamo la loro completezza o accuratezza; né promettiamo di garantire che il sito Web rimanga disponibile o che il materiale sul sito Web sia aggiornato."}
                      </p>
                      <h6 className="Help_page-content-h6">
                        {" "}
                        {storelanguage === "English"
                          ? "Disclaimer"
                          : "Disclaimer"}
                      </h6>
                      <p className="Help_page-content-p">
                        {" "}
                        {storelanguage === "English"
                          ? "To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will"
                          : "Nella misura massima consentita dalla legge applicabile, escludiamo tutte le dichiarazioni, garanzie e condizioni relative al nostro sito Web e all'utilizzo di questo sito Web. Nulla in questo disclaimer lo farà"}
                      </p>
                      <ul>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "limit or exclude our or your liability for death or personal injury;"
                            : "limitare o escludere la nostra o la vostra responsabilità per morte o lesioni personali;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "limit or exclude our or your liability for fraud or fraudulent misrepresentation;"
                            : "limitare o escludere la nostra o la vostra responsabilità per frode o falsa dichiarazione fraudolenta;"}
                        </li>
                        <li className="Help_page-content-p">
                          {" "}
                          {storelanguage === "English"
                            ? "limit any of our or your liabilities in any way that is not permitted under applicable law; or"
                            : "limitare le nostre o le vostre responsabilità in qualsiasi modo non consentito dalla legge applicabile; O"}
                        </li>
                        <li className="Help_page-content-p">
                          {storelanguage === "English"
                            ? "exclude any of our or your liabilities that may not be excluded under applicable law."
                            : "escludere qualsiasi nostra o vostra responsabilità che non può essere esclusa ai sensi della legge applicabile."}{" "}
                        </li>
                      </ul>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty."
                          : "Le limitazioni ei divieti di responsabilità stabiliti in questa Sezione e altrove in questa esclusione di responsabilità: (a) sono soggetti al paragrafo precedente; e (b) disciplinare tutte le responsabilità derivanti dalla clausola di esclusione di responsabilità, comprese le responsabilità derivanti da contratto, in illecito e per violazione del dovere legale."}
                      </p>
                      <p className="Help_page-content-p">
                        {storelanguage === "English"
                          ? "As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature."
                          : "Finché il sito Web e le informazioni e i servizi sul sito Web sono forniti gratuitamente, non saremo responsabili per eventuali perdite o danni di qualsiasi natura."}
                      </p>
                    </>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Help;
